@tailwind base;
@tailwind components;
@tailwind utilities;

*::-webkit-scrollbar {
    width: 6px;
}

*::-webkit-scrollbar-track {
    background: #a8a8a8;
    border-radius: 0px;
}

*::-webkit-scrollbar-thumb {
    background-color: #262626;
    border-radius: 0px;
}